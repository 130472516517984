import classNames from 'classnames'
import type { CommonProps } from '../@types/common'
import './progress-bar-style.css'
import { SIZES } from '../utils/constants'

interface LineProps extends CommonProps {
    percent: number
    strokeColor?: string
    size?: 'sm' | 'md' | 'lg'
    clipsProgress?: boolean
}

const Line = (props: LineProps) => {
    const { percent, size, children, clipsProgress } = props

    const progressBackgroundClass = classNames(
        // 'progress-bg',
        size === SIZES.SM ? 'h-2' : size === SIZES.MD ? 'h-3' : 'h-8'
    )

    // Get the progress bar element
    const progressBar = document.getElementById('progress-bar')
    const dotsContainer = document.createElement('div')
    dotsContainer.id = 'dots-container'
    progressBar?.appendChild(dotsContainer)

    const dotsCount = 12 // Set the number of dots you want
    const dotSizeMin = 4 // Minimum dot size
    const dotSizeMax = 12 // Maximum dot size
    const dotSpeedMin = 4 // Minimum speed of dots
    const dotSpeedMax = 8 // Maximum speed of dots

    // Function to generate a random number between min and max
    const randomInRange = (min:any, max:any) => Math.random() * (max - min) + min

    // Function to create a random dot
    const createDot = () => {
        const dot = document.createElement('div')
        dot.classList.add('dot')
        dot.style.top = '100%' // Start dots from the bottom of the progress bar
        dot.style.left = randomInRange(0, 100) + '%' // Random left position
        const dotSize = randomInRange(dotSizeMin, dotSizeMax) + 'px' // Random dot size
        dot.style.width = dotSize
        dot.style.height = dotSize
        dot.style.animationDuration =
            randomInRange(dotSpeedMin, dotSpeedMax) + 's' // Random speed of dots
        dotsContainer.appendChild(dot) // Append the dot to the dots container

        // Remove dot when it reaches the top
        dot.addEventListener('animationiteration', () => {
            dotsContainer.removeChild(dot)
            createDot()
        })
    }

    // Create specified number of dots
    for (let i = 0; i < dotsCount; i++) {
        createDot()
    }

    return (
        <>
            <div className="progress-wrapper">
                <div className="progress-inner">
                    {/* Percent is in the middle of the bg-color of the progress */}
                    <div
                        id="progress-bar"
                        style={{ width: `${clipsProgress == true && percent < 10 ? 9 : percent}%` }}
                        className={`rounded-full ` + progressBackgroundClass}
                    >
                        <h4 className="flex h-full items-center justify-center pt-0.5 !text-white text-base sm:text-xl !z-50">
                            {size == SIZES.LG && `${percent}%`}
                        </h4>
                    </div>

                    {/* Percent is in the middle of the progress bar */}
                    {/* <div className="relative">
                        <div
                            id="progress-bar"
                            style={{ width: `${30}%` }}
                            className="rounded-full"
                        ></div>
                        <h4 className="absolute inset-0 flex items-center justify-center text-white">
                            {percent}%
                        </h4>
                    </div> */}
                </div>
            </div>
            {children}
        </>
    )
}

Line.displayName = 'ProgressLine'

export default Line
