import { Skeleton } from '@/components/ui'
import Card from '@/components/ui/Card'

const PlanSkeleton = () => {
    return (
        <Card className="!w-full lg:!w-96">
            <div className="flex flex-col justify-between h-full min-h-[425px]">
                {/* Header */}
                <Skeleton height={30} width="100%" className="mb-4" />

                {/* Features List */}
                <div className="flex flex-col gap-2 mb-4">
                    {Array.from({ length: 5 }).map((_, index) => (
                        <div key={index} className="flex items-center gap-2">
                            <Skeleton height={20} width={20} />
                            <Skeleton height={20} width="100%" />
                        </div>
                    ))}
                </div>

                {/* Platform Icons */}
                <div className="flex flex-row gap-2 mt-4">
                    {Array.from({ length: 4 }).map((_, index) => (
                        <Skeleton key={index} height={32} width={32} />
                    ))}
                </div>

                {/* Button */}
                <Skeleton height={40} width="100%" className="mt-4" />
            </div>
        </Card>
    )
}

export default PlanSkeleton
