import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    apiGetProjectList,
    apiGetScrumBoardtMembers,
    apiPutProjectList,
    getVideoDetailsById,
    uploadVideoApi,
    deleteVideoByIdApi,
    uploadVideoSignedUrlApi,
} from '@/services/ProjectService'

type Member = {
    id: string
    name: string
    email: string
    img: string
}

type Project = {
    id: number
    name: string
    category: string
    desc: string
    attachmentCount: number
    totalTask: number
    completedTask: number
    progression: number
    dayleft: number
    status: string
    member: Omit<Member, 'id' | 'email'>[]
    _id?:string
}

type ProjectList = Project[]

type Query = {
    sort: 'asc' | 'desc' | ''
    search: ''
    sortBy ?: string
    sortType ?: number
    limit ?: number | null
    skip ?: number
    title ?:  string
}

type GetProjectListRequest = Query

type GetProjectListResponse = ProjectList

type GetScrumBoardtMembersResponse = {
    allMembers: Member[]
}

export type VideoByIdRequest = {
    id: string
}
export type DeleteVideoByIdRequest = {
    [key: string]: any
}
type Provider = {
    id: string
    name: string
}

export interface IVideoDetails {
    provider: VideoDetailProvider;
    _id: string;
    title: string;
    url: string;
    userId: string;
    thumbnail: string;
    progress: number | null;
    createdAt: string;
    __v: number | null;
}

interface VideoDetailProvider {
    _id: string;
    name: string;
}

// TODO: need to Resolve getting data from await Dispatch at 
// "ActionBar.tsx(line51)" and "ShortListContent.tsx(96)"  function before giving it type
export type VideoByIdResponse = Partial<IVideoDetails> & {
    id: string
    title: string
    url: string
    provider: Provider
    payload ?: { progress: number }
}

type PutProjectListRequest = {
    id: string
    name: string
    desc: string
    totalTask?: number
    completedTask?: number
    progression: number
    member?: Omit<Member, 'email' | 'id'>[]
}

type PutProjectListResponse = ProjectList

export type ProjectListState = {
    loading: boolean
    projectList:ProjectList
    progress: number
    allMembers: {
        value: string
        label: string
        img: string
    }[]
    view: 'grid' | 'list'
    query: Query
    newProjectDialog: boolean
    isSort:boolean
    isVideoUploaded: boolean
}

export const SLICE_NAME = 'projectList'

export const getList = createAsyncThunk(
    SLICE_NAME + '/getList',
    async (data: GetProjectListRequest) => {
        const response = await apiGetProjectList<
            GetProjectListResponse,
            GetProjectListRequest
        >(data)
        return response.data
    }
)

export const getMembers = createAsyncThunk(
    SLICE_NAME + '/getMembers',
    async () => {
        const response =
            await apiGetScrumBoardtMembers<GetScrumBoardtMembersResponse>()
        const data = response.data.allMembers.map((item) => ({
            value: item.id,
            label: item.name,
            img: item.img,
        }))
        return data
    }
)

export const putProject = createAsyncThunk(
    SLICE_NAME + '/putProject',
    async (data: PutProjectListRequest) => {
        const response = await apiPutProjectList<
            PutProjectListResponse,
            PutProjectListRequest
        >(data)
        return response.data
    }
)


export const getVideoById = createAsyncThunk(
    SLICE_NAME + '/getVideoById',
    async (data: VideoByIdRequest) => {
        const response = await getVideoDetailsById<
            VideoByIdResponse,
            VideoByIdRequest
        >(data)
        return response.data
    }
)

export const deleteVideoById = createAsyncThunk(
    SLICE_NAME + '/deleteVideoById',
    async (id: DeleteVideoByIdRequest) => {
        const response = await deleteVideoByIdApi<
            VideoByIdResponse,
            DeleteVideoByIdRequest
        >(id)
        return response.data
    }
)

export const uploadVideo = createAsyncThunk(
    SLICE_NAME + '/uploadVideo',
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await uploadVideoApi(data);
            return response;
        } catch (error: any) {
            if (error?.response && error?.response?.data) {
                // Log the detailed error data from the 400 response
                console.log("Error response data:", error?.response?.data);
                // Pass this data to the rejected action as the payload
                return rejectWithValue(error?.response?.data);
            } else {
                console.error("Unexpected error:", error);
                return rejectWithValue(error?.message);
            }
        }
    }
);

export const uploadVideoSignedUrl = createAsyncThunk(
    SLICE_NAME + '/uploadVideoSignedUrl',
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await uploadVideoSignedUrlApi(data);
            return response;
        } catch (error: any) {
            if (error?.response && error?.response?.data) {
                // Log the detailed error data from the 400 response
                console.log("Error response data:", error?.response?.data);
                // Pass this data to the rejected action as the payload
                return rejectWithValue(error?.response?.data);
            } else {
                console.error("Unexpected error:", error);
                return rejectWithValue(error?.message);
            }
        }
    }
);

const initialState: ProjectListState = {
    loading: false,
    projectList: [],
    allMembers: [],
    view: 'grid',
    query: {
        sort: 'asc',
        search: '',
        skip: 0,
    },
    newProjectDialog: false,
    progress: 0,
    isSort:true,
    isVideoUploaded: false
}

const projectListSlice = createSlice({
    name: `${SLICE_NAME}/state`,
    initialState,
    reducers: {
        toggleView: (state, action) => {
            state.view = action.payload
        },
        setVideoProgress: (state, action) => {
            state.progress = action.payload
        },
        toggleSort: (state, action) => {
            state.query.sort = action.payload
        },
        toggleIsSort: (state, action) => {
            state.isSort = action.payload
        },
        setSearch: (state, action) => {
            state.query.search = action.payload
        },
        setSkipped: (state, action) => {
            state.query.skip = action.payload
        },
        toggleNewProjectDialog: (state, action) => {
            state.newProjectDialog = action.payload
        },
        setProjectList: (state, action) => {
            state.projectList = action.payload
            state.loading = false
        },
        setIsVideoUploaded: (state, action) => {
            state.isVideoUploaded = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getList.fulfilled, (state, action) => {
                state.projectList = action.payload
                state.loading = false
            })
            .addCase(getList.pending, (state) => {
                state.loading = true
            })
            .addCase(getMembers.fulfilled, (state, action) => {
                state.allMembers = action.payload
            })
            .addCase(putProject.fulfilled, (state, action) => {
                state.projectList = action.payload
            })
    },
})

export const { toggleView, toggleSort,toggleIsSort, toggleNewProjectDialog, setSearch, setSkipped, setVideoProgress, setProjectList, setIsVideoUploaded } =
    projectListSlice.actions

export default projectListSlice.reducer
