import { forwardRef } from 'react'
import classNames from 'classnames'
import { useTabs } from './context'
import useCallbackRef from '../hooks/useCallbackRef'
import { useConfig } from '../ConfigProvider'
import type { CommonProps } from '../@types/common'
import type { TabsValue } from './context'
import type { ReactNode } from 'react'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'

export interface TabNavProps extends CommonProps {
    disabled?: boolean
    icon?: string | ReactNode
    value: TabsValue
    data?: Array<any>
    connected?: boolean
}

const TabNav = forwardRef<HTMLDivElement, TabNavProps>((props, ref) => {
    const {
        value: valueProp,
        disabled,
        className,
        icon,
        children,
        data,
        connected,
        ...rest
    } = props

    const { value, onValueChange, variant } = useTabs()
    const isSelected = valueProp === value

    const { primaryColorLevel } = useConfig()
    const navigate = useNavigate()

    const onTabNavClick = useCallbackRef(() => {
        if(!connected) navigate('/app/settings')
        if (!isSelected && !disabled) {
            onValueChange?.(valueProp)
        }
    })

    const color = `primary-${primaryColorLevel}`

    const isSocialVariant = variant === 'social'

    const tabNavClass = classNames(
        'tab-nav',
        `tab-nav-${variant}`,
        isSelected &&
            `tab-nav-active text-${color} dark:text-primary-100`,
        isSelected && variant === 'underline' && `border-${color}`,
        isSelected &&
            variant === 'pill' &&
            `bg-primary-50 dark:bg-${color} dark:text-gray-100`,
        disabled && 'tab-nav-disabled',
        !disabled &&
            !isSelected &&
            `hover:text-${color} dark:hover:text-primary-100`,
        isSocialVariant && 'social-tab-nav',
        className
    )

    return (
        // <div
        //     ref={ref}
        //     className={tabNavClass}
        //     role="tab"
        //     aria-selected={isSelected}
        //     tabIndex={0}
        //     onClick={onTabNavClick}
        //     onKeyDown={onTabNavClick}
        //     {...rest}
        // >
        //     {icon && <div className="tab-nav-icon">{icon}</div>}
        //     {children}
        // </div>
        <div
            ref={ref}
            className={tabNavClass}
            role="tab"
            aria-selected={isSelected}
            tabIndex={0}
            onClick={onTabNavClick}
            onKeyDown={onTabNavClick}
            {...rest}
        >
            {isSocialVariant ? (
                // Render social platform buttons here
                <div className="flex flex-col md:flex-row md:items-center gap-2">
                    {data && data.map((platformData, index) => (
                        <div key={index} className={`flex items-center gap-2`}>
                            <Button
                                size="md"
                                className="flex flex-row gap-2 items-center justify-center !px-4"
                                // disabled={platformData.connected}
                                onClick={(e) => {
                                    e.preventDefault()

                                    if (!platformData.connected) {
                                        console.log(
                                            `User need to connect ${platformData.platform}`
                                        )
                                    }
                                }}
                            >
                                <img
                                    src={`/img/voodoo/${platformData.platform}.png`}
                                    width={24}
                                />
                                <div>
                                    {platformData.connected
                                        ? platformData.platform === 'youtube'
                                            ? 'YouTube'
                                            : platformData.platform === 'tiktok'
                                            ? 'TikTok'
                                            : platformData.platform ===
                                              'instagram'
                                            ? 'Instagram'
                                            : platformData.platform ===
                                              'facebook'
                                            ? 'Facebook'
                                            : 'Connect'
                                        : 'Connect'}
                                </div>
                            </Button>
                        </div>
                    ))}
                </div>
            ) : (
                // Render regular tab content here
                <>
                    {icon && <div className="tab-nav-icon">{icon}</div>}
                    {children}
                </>
            )}
        </div>
    )
})

TabNav.displayName = 'TabNav'

export default TabNav
