interface HeaderExtraContentInterface {
    selectedOptionPrice: number | string
    productName: string
    planData: {
        price: number
        priceUnit: string
    }
    billingCycle: string
}
const HeaderExtraContent = ({
    selectedOptionPrice,
    productName,
    planData,
    billingCycle,
}: HeaderExtraContentInterface) => {
    return (
        <span className="flex items-center">
            <h5 className="flex gap-1 items-center">
                {`${selectedOptionPrice}` === 'Contact us for a quote' ? (
                    <a
                        className="text-xl cursor-pointer bg-transparent"
                        target="_blank"
                        href="https://forms.gle/ryA6umJyAsdnevCs7" rel="noreferrer"
                    >
                        Contact Us
                    </a>
                ) : billingCycle === 'Yearly' && productName === 'Pro' ? (
                    <div className="flex items-center gap-1">
                        <span className="text-lg line-through text-gray-600">
                            ${((+planData.price as number) / 10).toFixed(0)}
                        </span>
                        <span className="text-xl">
                            $
                            {Math.floor(
                                ((+planData.price as number) * 10) / 120
                            )}
                        </span>
                    </div>
                ) : (
                    <span className="text-xl">${planData.price} </span>
                )}

                {typeof selectedOptionPrice === 'number' && (
                    <span className="text-sm text-gray-600">
                        {billingCycle !== 'Life Time' && planData.priceUnit}
                    </span>
                )}
            </h5>
        </span>
    )
}

export default HeaderExtraContent
