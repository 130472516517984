import { forwardRef } from 'react'
import classNames from 'classnames'
import { useConfig } from '../ConfigProvider'
import { useForm } from '../Form/context'
import { useInputGroup } from '../InputGroup/context'
import { CONTROL_SIZES } from '../utils/constants'
import type { CommonProps, TypeAttributes } from '../@types/common'
import Button from '../Button'

export interface AddonProps extends CommonProps {
    size?: TypeAttributes.ControlSize
    variant?: 'default' | 'button'
}

const Addon = forwardRef<HTMLDivElement, AddonProps>((props, ref) => {
    const { size, children, className } = props

    const { controlSize } = useConfig()
    const formControlSize = useForm()?.size
    const inputGroupSize = useInputGroup()?.size

    const inputAddonSize =
        size || inputGroupSize || formControlSize || controlSize

    const isButton = props.variant === 'button'

    const addonClass = classNames(
        'input-addon',
        `input-addon-${inputAddonSize} h-${CONTROL_SIZES[inputAddonSize]}`,
        className
    )

    return isButton ? (
        <div
            ref={ref}
            className="
            input-addon
            input-addon-button
            h-100
            d-flex
            align-items-center
            justify-content-center
            border
            border-start-0
            border-top-0
            border-bottom-0
            border-end-0
            rounded-0
            bg-transparent
            text-decoration-none
            !p-0
        "
        >
            <Button
                className="!rounded-none"
                onClick={(e) => e.preventDefault()}
            >
                {children}
            </Button>
        </div>
    ) : (
        <div ref={ref} className={addonClass}>
            {children}
        </div>
    )
})

Addon.displayName = 'Addon'

export default Addon
